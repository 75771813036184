import React from 'react'
import Img from 'gatsby-image'

import { graphql, useStaticQuery } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { TitleSection } from '../../common/Index.styled'
import Section from '../../common/Section'

const PageContentNews = () => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "asnews_promo.png" }) {
        childImageSharp {
          fluid(maxHeight: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.news.title" />
      </TitleSection>
      <Img
        fixed={file.childImageSharp.fluid}
        style={{ height: 420, width: '100%' }}
        objectFit="contain"
      />
    </Section>
  )
}

export default PageContentNews
