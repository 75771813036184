import React from 'react'
import _times from 'lodash/times'
import _get from 'lodash/get'

import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { TitleSection } from '../../common/Index.styled'
import Section from '../../common/Section'

const PageContentHealth = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "health" } }) {
        edges {
          node {
            name
            childImageSharp {
              fixed(height: 720) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.as-health.content2.subtitle1" />
      </TitleSection>
      <FormattedHTMLMessage id="solutions.as-health.content2.description1" />
      <TitleSection>
        <FormattedMessage id="solutions.as-health.content2.subtitle2" />
      </TitleSection>
      <FormattedHTMLMessage id="solutions.as-health.content2.description2" />
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <Img
          fluid={_get(
            edges.find(({ node }) => node.name === 'health_steps'),
            'node.childImageSharp.fixed'
          )}
          style={{ height: 420, width: 620 }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
      <TitleSection>
        <FormattedMessage id="solutions.as-health.content2.subtitle3" />
      </TitleSection>
      <h3>
        <FormattedMessage id="solutions.as-health.content2.content.subtitle1" />
      </h3>
      <ul>
        {_times(6, Number).map((index, key) => (
          <li key={key}>
            <FormattedHTMLMessage
              id={`solutions.as-health.content2.content.list1.item${index + 1}`}
            />
          </li>
        ))}
      </ul>
      <h3>
        <FormattedMessage id="solutions.as-health.content2.content.subtitle2" />
      </h3>
      <ul>
        {_times(5, Number).map((index, key) => {
          return (
            <>
              <li key={key}>
                <FormattedHTMLMessage
                  id={`solutions.as-health.content2.content.list2.item${index +
                    1}`}
                />
              </li>
              {!index ? (
                <ul>
                  {_times(3, Number).map((index, key) => (
                    <li key={key}>
                      <FormattedMessage
                        id={`solutions.as-health.content2.content.list2.item1_subitem${index +
                          1}`}
                      />
                    </li>
                  ))}
                </ul>
              ) : null}
            </>
          )
        })}
      </ul>
      <TitleSection>
        <FormattedMessage id="solutions.as-health.content2.subtitle4" />
      </TitleSection>
      <FormattedHTMLMessage id="solutions.as-health.content2.description4" />
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <Img
          fluid={_get(
            edges.find(({ node }) => node.name === 'health_program'),
            'node.childImageSharp.fixed'
          )}
          style={{ height: 420, width: 620 }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
      <FormattedHTMLMessage id="solutions.as-health.content2.description5" />
      <ul>
        {_times(10, Number).map((index, key) => {
          return (
            <>
              <li key={key}>
                <FormattedHTMLMessage
                  id={`solutions.as-health.content2.list1.item${index + 1}`}
                />
              </li>
              {index === 9 ? (
                <ul>
                  {_times(2, Number).map((index, key) => (
                    <li key={key}>
                      <FormattedHTMLMessage
                        id={`solutions.as-health.content2.list1.item10_subitem${index +
                          1}`}
                      />
                    </li>
                  ))}
                </ul>
              ) : null}
            </>
          )
        })}
      </ul>
    </Section>
  )
}

export default PageContentHealth
