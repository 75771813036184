import React from 'react'
import _times from 'lodash/times'

import { FormattedMessage } from 'react-intl'
import { TitleSection } from '../../common/Index.styled'
import Section from '../../common/Section'
import { ContractButtonSmall } from '../SolutionDetail.styled'

const PageContentSeguridad = () => {
  return (
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.seguridad.title" />
      </TitleSection>
      <div>
        <p>
          <FormattedMessage id="solutions.seguridad.content.p1" />
        </p>
        <h2>
          <FormattedMessage id="solutions.seguridad.content.subtitle" />
        </h2>
        <ul>
          {_times(8, String).map((value, key) => (
            <li key={key}>
              <FormattedMessage
                id={`solutions.seguridad.content.list.item${value}`}
              />
            </li>
          ))}
        </ul>
        <div style={{ margin: '0 auto' }}>
          <div style={{ marginLeft: '4rem' }}>
            {_times(2, String).map((value, key) => (
              <p key={key}>
                <FormattedMessage
                  id={`solutions.seguridad.content.second_list.item${value}`}
                />
              </p>
            ))}
            <ul>
              {_times(6, String).map((value, key) => (
                <li key={key}>
                  <FormattedMessage
                    id={`solutions.seguridad.content.third_list.item${value}`}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <ContractButtonSmall to="/contacto/" small>
          <FormattedMessage id="element.button_hire" />
        </ContractButtonSmall>
      </div>
    </Section>
  )
}

export default PageContentSeguridad
