import React from 'react'
import Layout from '../components/Layout'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import Section from '../components/common/Section'
import FooterContact from '../components/FooterContact'
import image_background from '../images/solutions/fiscal.png'
import { FormattedHTMLMessage } from 'react-intl'
import { Row, Col } from 'react-styled-flexboxgrid'
import icon_AS from '../images/icon_as.png'
import PageContentContable from '../components/Solutions/Pages/Contable'
import PageContentImpuestos from '../components/Solutions/Pages/Impuestos'
import PageContentInternational from '../components/Solutions/Pages/International'
import PageContentSeguridad from '../components/Solutions/Pages/Seguridad'
import PageContentSectorFinanciero from '../components/Solutions/Pages/SectorFinanciero'
import PageContentAdvisory from '../components/Solutions/Pages/Advisory'
import PageContentLegal from '../components/Solutions/Pages/Legal'
import PageContentPublicidad from '../components/Solutions/Pages/Publicidad'
import PageContentFacturacion from '../components/Solutions/Pages/Facturacion'
import PageContentOmnium from '../components/Solutions/Pages/Omnium'
import PageContentNews from '../components/Solutions/Pages/News'
import PageContentHealth from '../components/Solutions/Pages/Health'
import PageContentSustentabilidad from '../components/Solutions/Pages/Sustentabilidad'

const OurGroupSolution = props => {
  const {
    pageContext: { slug, title, description },
  } = props

  console.log('PROPS', props)
  const getContentService = key => {
    switch (key) {
      case 'contabilidad':
        return <PageContentContable />
      case 'impuestos':
        return <PageContentImpuestos />
      case 'internacional':
        return <PageContentInternational />
      case 'bpo':
        return (
          <>
            <PageContentSeguridad />
            <PageContentFacturacion />
          </>
        )
      case 'sector-financiero':
        return <PageContentSectorFinanciero />
      case 'advisory':
        return <PageContentAdvisory />
      case 'legal':
        return <PageContentLegal />
      case 'marketing-contenido-digital':
        return <PageContentPublicidad />
      case 'omnium':
        return <PageContentOmnium />
      case 'news':
        return <PageContentNews />
      case 'health':
        return <PageContentHealth />
      case 'sustentabilidad':
        return <PageContentSustentabilidad />
      default:
        return null
    }
  }

  console.log('SLUG', slug)
  return (
    <Layout title={title} description={description} {...props}>
      <SectionHeaderImage image={image_background} position="90% 0" />
      <Section height="120px">
        <Row center="xs">
          <Col xs={6}>
            <Row center="xs" middle="xs">
              <img
                src={icon_AS}
                height={55}
                alt="Icon AS Consulting"
                style={{ marginRight: '0.5rem' }}
              />
              <h1>
                <FormattedHTMLMessage id={`ourGroup.solutions.${slug}.title`} />
              </h1>
            </Row>
          </Col>
        </Row>
        <FormattedHTMLMessage id={`ourGroup.solutions.${slug}.description`} />
      </Section>
      {getContentService(slug)}
      <FooterContact />
    </Layout>
  )
}

export default OurGroupSolution
