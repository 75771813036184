import React from 'react'
import _times from 'lodash/times'
import { FormattedMessage } from 'react-intl'

import Section from '../../common/Section'

const PageContentOmnium = () => {
  return (
    <Section>
      <ul>
        {_times(8, Number).map((index, key) => (
          <li key={key}>
            <FormattedMessage
              id={`solutions.omnium.content.list1.item${index + 1}`}
            />
          </li>
        ))}
      </ul>
      <h3>
        <FormattedMessage id={`solutions.omnium.content.subtitle1`} />
      </h3>
      <ul>
        {_times(8, Number).map((index, key) => (
          <li key={key}>
            <FormattedMessage
              id={`solutions.omnium.content.list2.item${index + 1}`}
            />
          </li>
        ))}
      </ul>
      <FormattedMessage id={`solutions.omnium.content.footer`} />
    </Section>
  )
}

export default PageContentOmnium
